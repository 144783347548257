<template>
  <v-card class="video-container" align="center" flat>
      <iframe class="video-frame" :src="url" scrolling="no" allowfullscreen/>
  </v-card>
</template>

<script>
  export default {
    name: 'Video',
    props: {
      url: String,
    },
    data: () => ({
     }),
  }
</script>


<style scoped>
  .video-container {
    display: inline-block;
    height: 100%;
    text-align: center;
  }
  .video-frame {
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
      width: 320px;
      height: 240px;
      border: 0;
  }
</style>