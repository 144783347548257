import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth_token: null,
    profile: null,
  },
  mutations: {
    set_auth_token (state, auth_token) {
      state.auth_token = auth_token;
    },
    set_profile (state, profile) {
      state.profile = profile;
    }
  },
  actions: {
  },
  modules: {
  }
})
