<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center text-uppercase">
        <span class="font-weight-light">Mimetic</span>
        <span>GarageOpener</span>
      </div>

      <v-spacer></v-spacer>
      <button v-if="profile == null" v-google-signin-button="clientId" class="google-signin-button">Login</button>
      <span v-if="profile"> {{ profile.name }} </span>
      <v-btn v-if="profile" class="mx-5" text @click="logout">Log Out</v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script src="https://apis.google.com/js/platform.js?onload=renderButton" async defer></script>
<script>
import axios from 'axios';
import jwt_decode from "jwt-decode";
import GoogleSignInButton from 'vue-google-signin-button-directive'
export default {
  name: 'App',
  directives: {
    GoogleSignInButton
  },
  mounted() {
    this.authURI = process.env.VUE_APP_AUTH_SERVER
  },
  data: () => ({
    authURI:null,
  }),
  computed: {
    profile() {
      return this.$store.state.profile;
    },
    is_profile_loaded() {
      return this.profile != null;
    },
  },
  methods: {
    OnGoogleAuthSuccess (idToken) {
      // Receive the idToken and make your magic with the backend
      console.log(idToken);
      axios.post("https://" + this.authURI + "/api/id-token", {
        id_token: idToken,
      })
      .then(this.idTokenPostHandler)
      .catch(function (error) {
        console.log(error);
      });
    },
    OnGoogleAuthFail (error) {
      console.log(error)
    },
    idTokenPostHandler (response) {
      let auth_token = response.data.auth_token;
      let decoded_auth_token = jwt_decode(auth_token);
      this.$store.commit('set_auth_token', auth_token)
      this.$store.commit('set_profile', decoded_auth_token)
    },
    logout() {
      this.$store.commit('set_auth_token', null)
      this.$store.commit('set_profile', null)
    }
  },
};
</script>
