<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols=10 align="center">
        <Video :url="video_endpoint" />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols=4 align="center">
        <v-btn
          @click="pressOpener"
          color="primary"
          dark
          >
          <span>
            Press Opener
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols=10>
        <v-card class="py-2 px-5">
          <v-row>
            <v-text-field
              label="Closed Switch"
              v-model="closedSwitchState"
              :disabled="true"
            />
            <v-text-field
              label="Opened Switch"
              v-model="openedSwitchState"
              :disabled="true"
            />
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Video from '../components/Video.vue'

  export default {
    name: 'Home',

    components: {
        Video,
    },
    data: () => {
      return {
        video_endpoint:"https://mimeticmaker.tplinkdns.com/video",
        closedSwitchState:"Active",
        openedSwitchState:"Inactive",
      }
    },
    methods: {
      pressOpener () {
        console.log("Pressed Button")
      }
    }
  }
</script>
